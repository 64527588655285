<template>
  <div class="outer-wrapper">
    <!--<div class="img-wrapper" v-for="(ele,index) in imgList" :key="index" :style="ele.style" @mouseover="hoverIndex=index" @mouseleave="hoverIndex=-1">
        <img :src="ele.url" alt="" class="img-item" @click="openD">
        <p v-show="hoverIndex===index" @click="openD" style="cursor:pointer;">{{ele.content}}</p>
        </div>
    <Modal
        title="客流数据专项分析"
        v-model="modalSwitch"
        :closable="false" width="800"
        :styles="{top: '5px'}">
        <div style="height:600px;"></div>
        
        <div slot="footer"></div>
    </Modal>-->

    <Collapse v-model="cosw">
      <Panel name="1">
        2020年安评数据
        <div slot="content">
          <Tabs>
            <TabPane label="换乘站在用运行图运能情况" name="name1">
              <query-table
                :columnsFormatData="columnsFormatData"
                askData="transferList"
                :refreshData="update_count"
                main_width="1260px"
              ></query-table>
            </TabPane>
            <TabPane label="各线路高断面客流"
              ><div
                style="
                  text-align: center;
                  padding: 40px 0;
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: center;
                "
              >
                <div
                  v-for="(item, subIndex) in passenger"
                  :key="subIndex + 'i'"
                  style="margin: 30px"
                >
                  <i-circle
                    :size="200"
                    :trail-width="6"
                    :stroke-width="7"
                    :percent="percentCal(item.rate * 100)"
                    stroke-linecap="square"
                    :stroke-color="colorCal(item.rate * 100)"
                  >
                    <div class="demo-Circle-custom">
                      <p
                        style="font-size: 24px; line-height: 200%; color: #000"
                      >
                        {{ item.line_name }}
                      </p>
                      <p>断面客流量：{{ item.flow }}</p>
                      <p>计划运能：{{ parseInt(item.flow/item.rate) }}</p>
                      <p>
                        满载率：
                        <i>{{ Math.round(item.rate * 100) + "%" }}</i>
                      </p>
                    </div>
                  </i-circle>
                </div>
              </div>
            </TabPane>
          </Tabs>
        </div>
      </Panel>
    </Collapse>
  </div>
</template>
<script>
import queryTable from "@/components/Common/QueryTable.vue";
export default {
  name: "emergency",
  components: {
    queryTable,
  },
  data() {
    return {
      hoverIndex: -1,
      modalSwitch: false,
      cosw: [1],
      passenger: [
        { line_name: "1号线", flow: 48711,  rate: 1.11 },
        { line_name: "2号线", flow: 28582,  rate: 0.81 },
        { line_name: "3号线", flow: 27326,  rate: 0.81 },
        { line_name: "4号线", flow: 33827, rate: 0.97 },
        { line_name: "5号线", flow: 18517,  rate: 0.56 },
        { line_name: "7号线", flow: 26568,  rate: 0.79 },
        { line_name: "10号线", flow: 7230, rate: 0.37 },
        { line_name: "18号线", flow: 10100,  rate: 0.43 },
      ],
      imgList: [
        {
          url: process.env.BASE_URL + "img/flow/f1.jpg",
          content: "客流统计数据和列车运行计划资料",
          style: "top:40px;left:420px;",
        },
        {
          url: process.env.BASE_URL + "img/flow/f2.jpg",
          content: "换乘站的客流组织方案",
          style: "top:230px;left:150px;",
        },
        {
          url: process.env.BASE_URL + "img/flow/f3.jpg",
          content: "换乘站现场客流情况",
          style: "top:230px;left:670px;",
        },
        {
          url: process.env.BASE_URL + "img/flow/f4.jpg",
          content: "视频监控及现场联动情况",
          style: "top:420px;left:420px;",
        },
      ],
      update_count: 0,
      columnsFormatData: [
        {
          title: "换乘站名称",
          key: "station_name",
          align: "center",
        },
        {
          title: "换乘线路",
          key: "transfer_line",
          align: "center",
          width: 180,
        },
        {
          title: "换入客量",
          key: "in_amount",
          align: "center",
          width: 190,
        },
        {
          title: "运能",
          key: "capacity",
          align: "center",
          width: 150,
        },
        {
          title: "综合匹配比",
          key: "ratio",
          align: "center",
          width: 150,
        },
      ],
    };
  },
  methods: {
    openD() {
      this.modalSwitch = true;
    },
    get_data() {
      let userToken = localStorage.getItem("current_user_token");
      this.$axios
        .post(this.$url + "/ask_for_passenger", {
          userToken: userToken,
        })
        .then((response) => {
          this.passenger = response.data.res_record;
        });
    },
    colorCal(value) {
      if (value < 50) {
        return "#5cb85c";
      } else if (value < 75) {
        return "#a9e723";
      } else if (value < 100) {
        return "#fee901";
      } else {
        return "#fe1901";
      }
    },
    percentCal(value) {
      if (value > 100) {
        return 100;
      } else {
        return value;
      }
    },
  },
  created() {
    // this.get_data();
  },
};
</script>
<style scoped>
.outer-wrapper {
  position: relative;
  min-height: 640px;
  width: 100%;
  border: 1px solid #aaa;
}
.img-wrapper {
  position: absolute;
  width: 600px;
}

.img-wrapper p {
  text-align: center;
  text-indent: 36px;
  position: absolute;
  top: 30px;
  width: 400px;
  padding: 20px;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
}

.img-item {
  width: 400px;
  cursor: pointer;
}

p {
  line-height: 18px;
  font-size: 12px;
}
</style>